.rtl .page-navigation__menu-toggle {
  right: 0;
  border-left: 1px solid #cccccc;
  border-right: initial;
  left: initial;
}

.rtl .page-navigation .level-1 {
  background-position: 0 -4806px;
}

.rtl .page-navigation .js-active .level-1 {
  background-position: 100% -4856px;
}

.rtl .page-navigation .depth-1 h3 {
  background-position: 0 -4806px;
}

.rtl .product-brief {
  float: right;
}

.rtl .mpp__product-grid .product-brief.product-brief--second {
  clear: left;
}
/* Inline CSS Arabic Begins */
.rtl {
  &.summary_results__page {
    letter-spacing: 0 !important;
  }
  &.section-store-locator.device-mobile .store-locator {
    .store-locator__nav .store-locator__nav-item {
      letter-spacing: 0 !important;
    }
    .door-row {
      .door-row__closest,
      .door-row__address {
        letter-spacing: 0 !important;
      }
    }
  }
  select,
  a.selectBox {
    letter-spacing: 0 !important;
  }
}

a.selectbox {
  letter-spacing: 0 !important;
}

.rtl {
  .link--bold,
  .spp-product__attribute p,
  .spp-product__details h5,
  .button--light,
  .button--dark,
  .btn,
  .form-submit,
  .device-mobile .page-sticky-footer .page-sticky-footer__right .menu a,
  .page-navigation .level-3,
  .cta,
  .spp-product__attribute h5 {
    letter-spacing: 0 !important;
  }
  .page-navigation__menu-toggle {
    right: 0;
    border-left: 1px solid #cccccc;
    border-right: initial;
    left: initial;
  }
  &.footer-forms-location-sms-email-signup-elc-nodeblock .email-signup .form-text {
    width: 100%;
  }
  &.page-navigation__menu_text {
    letter-spacing: 0;
  }
}
/* Inline CSS arabic ends */
/* Fixes of adding inline begins */
.device-mobile.rtl #colorbox.welcome15-overlay.alt_layout1 .email_popover .email_popover__content {
  width: auto;
}

.rtl {
  .welcome15-overlay.alt_layout1 .email_popover .email_input {
    padding-right: 0px;
    input.form-text {
      margin-right: 0px;
    }
  }
  .email_popover .email_input input.form-text {
    float: right;
  }
}

.device-mobile.rtl #colorbox.welcome15-overlay.alt_layout1 .email_popover .email_input input[type='submit'] {
  float: right;
  letter-spacing: 0;
}

.device-mobile.rtl #colorbox.welcome15-overlay.alt_layout1 .email_popover .email_input input.form-text {
  text-align: right;
}
/* Fixes of adding inline ends */
.rtl .form-submit {
  letter-spacing: 0 !important;
}

.rtl.link--bold {
  letter-spacing: 0 !important;
}

.rtl.device-mobile .page-sticky-footer .page-sticky-footer__right .menu a {
  letter-spacing: 0 !important;
}

.rtl .page-footer .language-select .language-select__language01 {
  padding-right: 0px !important;
}

.section-store-locator {
  &.device-mobile {
    .store-locator {
      .store-locator__nav {
        .store-locator__nav-item {
          &.first {
            border: none;
            text-align: center;
            width: 100%;
          }
          &.last {
            border: none;
            display: none;
          }
        }
      }
    }
  }
}
