.contact-us {
  &__salesforce {
    max-width: 800px;
    margin: 50px auto;
    @media #{$medium-up} {
      padding: 0 20px;
    }
    &-title {
      font-size: 50px;
      text-align: center;
    }
    .form-item {
      @include swap_direction(margin, 0 16px 12px 0);
      display: inline-block;
      label {
        letter-spacing: 0.15em;
        display: block;
        &.error {
          margin-top: 0;
        }
      }
      input[type='text'],
      input[type='email'] {
        @include swap_direction(padding, 0 12px 0 14px);
        border: 1px solid $color-lighter-gray-alt;
        height: 36px;
        width: 100%;
        display: block;
        margin: 0 0 10px;
        @media #{$medium-up} {
          width: 222px;
          margin: 0;
        }
      }
      &-form {
        &-first-name,
        &-email,
        &-address,
        &-country,
        &-zip {
          float: #{$ldirection};
          clear: both;
          label {
            display: none;
            @media #{$medium-up} {
              display: block;
            }
          }
        }
        &-last-name,
        &-email2,
        &-address2,
        &-city,
        &-mobile {
          float: #{$ldirection};
          label {
            display: none;
            @media #{$medium-up} {
              display: block;
            }
          }
        }
        &-news-letter {
          font-size: 12px;
          width: 100%;
          a {
            font-weight: bold;
            text-transform: uppercase;
          }
        }
        &-description {
          max-width: 460px;
          textarea {
            width: 100%;
            height: 200px;
          }
        }
        &-required {
          text-transform: uppercase;
          font-size: 12px;
          @media #{$medium-up} {
            display: block;
          }
        }
      }
      .form-item {
        margin: 0;
        &.contact-form {
          &__sub-question-type {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

.contact-form {
  &__section {
    &--question-types {
      &-titles {
        font-size: 20px;
        letter-spacing: 0;
        margin: 50px 0 8px;
        font-family: $optimaregular;
        @media #{$medium-up} {
          font-family: $optimalight;
          padding-bottom: 16px;
          font-size: 24px;
          border-bottom: solid 1px $color-lighter-gray-alt;
          margin: 50px 0 20px;
        }
      }
    }
  }
  &__sub-question-types {
    padding: 6px 22px 0;
  }
  &__question-type,
  &__sub-question-type {
    width: 100%;
  }
}

.rtl {
  .contact-us {
    &__salesforce {
      .form-item-form {
        &-first-name,
        &-email,
        &-address,
        &-country,
        &-news-letter,
        &-zip,
        &-last-name,
        &-email2,
        &-address2,
        &-city,
        &-mobile {
          float: #{$rdirection};
        }
      }
      input[type='radio'] {
        #{$ldirection}: auto;
        #{$rdirection}: -999px;
        & ~ label {
          @include swap_direction(padding, 0 24px 0 0);
          &:before,
          &:after {
            right: 0;
          }
        }
      }
      .form-item {
        @include swap_direction(margin, 0 0 16px 12px);
        .form-item {
          margin-bottom: 0;
          &.contact-form__sub-question-type {
            margin-bottom: 10px;
          }
        }
        &-form-news-letter {
          letter-spacing: 0;
        }
      }
      .form-submit {
        float: #{$rdirection};
      }
    }
  }
}
