@import '../../estee_base/scss/theme-bootstrap';

.store-locator {
  .local-search-form {
    &__intlcity {
      width: 100%;
      margin-bottom: 10px;
      @media #{$medium-up} {
        width: 142px;
        margin-bottom: 0;
        float: #{$rdirection};
      }
    }
  }
}

.rtl {
  .store-locator {
    .local-search-form {
      &__intlcity {
        @media #{$medium-up} {
          width: 162px;
          float: #{$ldirection};
        }
      }
    }
  }
}

body.rtl {
  .page-navigation {
    &.active-menu-toogle {
      #{$rdirection}: -120%;
      #{$ldirection}: unset;
      @media #{$xlarge-up} {
        #{$rdirection}: unset;
        #{$ldirection}: unset;
      }
    }
  }
  &.mobile-gnav-active {
    .page-navigation {
      #{$rdirection}: 0;
      transition: $rdirection $default-transition-duration;
      @media #{$xlarge-up} {
        #{$rdirection}: unset;
      }
    }
    .page-header#{$alt} {
      .page-navigation__menu-toggle {
        border-#{$rdirection}: none;
      }
    }
  }
  &.mobile-gnav-active,
  &.gnav-active {
    .page-header#{$alt} {
      .page-navigation__menu-icon {
        background-image: url('/media/export/cms/gnav/gnav_icons_rtl.png');
      }
    }
  }
  @media #{$xlarge-up} {
    .page-header {
      &#{$search} {
        &#{$alt-color-white} {
          .page-branding {
            margin-#{$rdirection}: 0;
          }
        }
      }
    }
  }
  .page-navigation__menu {
    text-align: $rdirection;
    .menu-ref {
      @media #{$xlarge-up} {
        &:first-child {
          padding-#{$rdirection}: 19px;
        }
      }
      &:hover .menu-ref__title {
        @media #{$xlarge-up} {
          &:before {
            #{$rdirection}: 0;
          }
        }
      }
      &--close {
        #{$ldirection}: 0;
        padding-#{$rdirection}: 6px;
      }
      .menu--lvl-2 {
        @media #{$xlarge-up} {
          #{$rdirection}: 50%;
        }
      }
      .menu--lvl-2,
      .menu--lvl-4 {
        text-align: $rdirection;
        #{$rdirection}: 111%;
        #{$ldirection}: unset;
        @media #{$xlarge-up} {
          @include swap_direction(padding, 40px 20px 20px 50px);
          #{$rdirection}: 0;
          #{$ldirection}: unset;
          &.menu--column-width__20,
          &.menu--column-width__25,
          &.menu--column-width__33 {
            & > li {
              float: $rdirection;
              text-align: $rdirection;
              margin-#{$rdirection}: 0;
              margin-#{$ldirection}: 0;
              &:first-child {
                margin-#{$rdirection}: 0;
                margin-#{$ldirection}: 0;
              }
            }
            & > li.menu__item--column {
              li.menu-item-image {
                .menu-item-image__content {
                  padding-#{$rdirection}: 10px;
                  padding-#{$ldirection}: 0;
                }
              }
            }
          }
          &.menu--column-width__33 {
            text-align: $rdirection;
          }
        }
        @media #{$xlarger-only} {
          &.menu--column-width__20 {
            & > li.menu__item {
              &.menu-item-image {
                margin-#{$ldirection}: 15px;
                margin-#{$rdirection}: 0;
              }
            }
          }
        }
        @media #{$xxlarge-up} {
          &.menu--column-width__20 {
            & > li.menu__item {
              &.menu-item-image {
                &:first-child {
                  margin-#{$ldirection}: 0;
                  margin-#{$rdirection}: -26px;
                }
              }
            }
          }
        }
        .menu-item-image {
          @media #{$xlarge-up} {
            float: $ldirection;
          }
        }
        & > li {
          @media #{$xlarge-up} {
            float: $rdirection;
          }
          &.menu__item--category-expandable {
            @include swap_direction(padding, 0 10px 0 0);
            @media #{$xlarge-up} {
              padding: 0;
            }
          }
        }
      }
    } // end menu-ref
    .menu-ref,
    .menu__item--lvl-3,
    .menu__item--lvl-5 {
      @include swap_direction(padding, 10px 18px 10px 0);
      @media #{$xlarge-up} {
        padding: 0 1em;
      }
      @media #{$device-ipad_only} {
        padding-#{$rdirection}: 25px;
      }
      &.menu__item--back {
        &:before {
          #{$rdirection}: 13px;
          #{$ldirection}: unset;
          background-image: url('/media/export/cms/gnav/gnav_icons_rtl.png');
        }
        @media #{$xlarge-up} {
          &:before {
            background-image: none;
            #{$rdirection}: unset;
            #{$ldirection}: unset;
          }
        }
      }
    }
    .menu-ref--has-children .menu-ref__title,
    .menu__item--category-expandable {
      &:after {
        #{$ldirection}: 15px;
        #{$rdirection}: unset;
        background-image: url('/media/export/cms/gnav/gnav_icons_rtl.png');
        @media #{$xlarge-up} {
          #{$rdirection}: unset;
          #{$ldirection}: unset;
          background-image: none;
        }
      }
    }
  }
}
